<template>
    <div ref="duibifenqubi" v-if="fenquData" id="duibifenqubi"></div>
    <no-data-temporarily v-else ></no-data-temporarily>
</template>

<script>
import NoDataTemporarily from '@/components/utils/no-data-temporarily.vue';
import { initResizeListener } from '@/utils/utils';
import { GetDianNengXiaoHaoForBarChart } from '../../../api/enery-analysis/eneryanalysis';

export default {
  data() {
    return {
      fenquData: null,
      dimensions: null,
      source: null,
      listSeries: [],
    };
  },
  components: {
    NoDataTemporarily,
  },
  props: {
    proId: String,
    selectPeriod: Array,
    periodType: String,
  },
  watch: {
    proId() {
      this.requestData();
    },
    selectPeriod() {
      this.requestData();
    },
    fenquData() {
      this.$nextTick(this.initData);
    },
    skinType() {
      this.initData();
    },
  },
  created() {
    this.requestData();
  },
  computed: {
    selectProject() {
      return this.$store.getters.selectProject;
    },
  },
  methods: {
    // 初始化数据
    requestData() {
      if (!this.proId) {
        return;
      }
      const [start, end] = this.selectPeriod;
      GetDianNengXiaoHaoForBarChart({
        DateType: this.periodType,
        BeginDate: start,
        EndDate: end,
        ...this.proId === this.selectProject.IdStr ? {
          ProjectIDstr: this.proId,
        } : {
          StationIdstr: this.proId,
        },
      })
        .then((res) => {
          this.fenquData = res.fenquData;
          this.dimensions = res.fenquData.dimensions;
          this.source = res.fenquData.source;

          this.listSeries = [];
          if (this.listSeries) {
            this.dimensions.forEach((item) => {
              if (item !== 'product') {
                this.listSeries.push({ type: 'bar' });
              }
            });
          }
        }).catch(() => {
          this.fenquData = null;
          this.dimensions = null;
          this.source = null;
          this.listSeries = [];
        });
    },
    initResizeListener(chart) {
      const resizeObserver = new ResizeObserver(() => {
        // 回调
        chart.resize();
      });
      // 监听对应的dom
      resizeObserver.observe(this.$refs.duibifenqubi);
    },
    initData() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('duibifenqubi'));
      if (myChart) {
        myChart.dispose();
        myChart = undefined;
        myChart = this.$echarts.init(document.getElementById('duibifenqubi'));
      }

      // 绘制图表
      myChart.setOption({
        color: this.skinType
          ? ['#0084EF', '#667EEB', '#75B2FB', '#08BED3', '#F67CA3', '#7FC4C2']
          : [
            '#00C1FE',
            '#F98E2A',
            '#07BE57',
            '#0084EF',
            '#F67CA3',
            '#7FC4C2',
            '#7a81b5'],
        grid: {
          bottom: 0,
          left: 100,
          top: 10,
          right: 20,
          barBorderRadius: 28,
          containLabel: true,
        },
        legend: {
          orient: 'vertical',
          top: 0,
          left: 0,
          textStyle: {
            color: 'auto',
          },
          icon: 'circle',
          itemWidth: 10, // 设置宽度
          itemHeight: 10,
          // itemWidth: 16,
          // itemHeight: 10,
          itemGap: 5,
        },
        dataset: {
          dimensions: this.dimensions,
          source: this.source,
        },
        tooltip: {},
        xAxis: {
          type: 'category',
          axisLabel: {
            color: 'auto',
          },
          axisLine: {
            color: this.skinType ? '#fff' : '#333',
            // color: '#fff',
          },
        },
        yAxis: {
          type: 'value',
          name: 'kWh',
          axisLine: {
            // color: this.skinType ? '#fff' : '#333',
            color: '#fff',
          },
          // axisLabel: {
          //   // color: this.skinType ? '#fff' : '#333',
          //   color: '#fff',
          // },
          axisLabel: {
            color: '#fff',
            formatter: '{value}',
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#ccc',
              opacity: '0.23',
            },
          },
        },
        series: this.listSeries,
        // series: [
        //   {
        //     type: 'bar',
        //     data: [],
        //     color: {
        //       type: 'linear', // 线性渐变
        //       x: 0,
        //       y: 0,
        //       x2: 0,
        //       y2: 1,
        //       colorStops: [{
        //         offset: 0,
        //         color: '#0084EF', // 0%处的颜色为红色
        //       }, {
        //         offset: 1,
        //         color: '#00C1FE', // 100%处的颜色为蓝
        //       },

        //       ],
        //     },
        //   },

        //   { type: 'bar' },
        //   { type: 'bar' },
        //   { type: 'bar' },
        //   { type: 'bar' },
        // ],
      });
      initResizeListener(myChart, this.$refs.duibifenqubi);
    },
  },
};
</script>
<style scoped>
.pie {
  position: relative;
  /* top: 0.1vh; */
  /* background-color: #fa7844; */
}
</style>
