<template>
<div class="dark">
  <el-table
        stripe
    size="mini"
    :data="tableData"
    height="100%"
    class='dark'
    style="width: calc(100% - 1.2rem);"
    :row-class-name="
      (...nu) => {
        return 'dark text-white';
      }
    "
  >
    <el-table-column label="日期" prop="name"
    style="width: calc(100% - 1.2rem);"></el-table-column>
    <el-table-column label="供电量(kWh)" prop="value"> </el-table-column>
  </el-table>
  </div>
</template>

<script>
import { GetGongNengGaiKuangAndBarChart } from '../../../api/enery-analysis/eneryanalysis';

export default {
  data() {
    return {
      tableData: [],
      keys: [],
    };
  },
  props: {
    proId: String,
    selectPeriod: Array,
    periodType: String,
  },
  watch: {
    proId() {
      this.requestData();
    },
    selectPeriod() {
      this.requestData();
    },
  },
  created() {
    this.requestData();
  },
  computed: {
    selectProject() {
      return this.$store.getters.selectProject;
    },
  },
  methods: {
    requestData() {
      if (!this.proId) {
        return;
      }
      const [start, end] = this.selectPeriod;
      GetGongNengGaiKuangAndBarChart({
        DateType: this.periodType,
        BeginDate: start,
        EndDate: end,
        ...this.proId === this.selectProject.IdStr ? {
          ProjectIDstr: this.proId,
        } : {
          StationIdstr: this.proId,
        },
      })
        .then((res) => {
          this.tableData = res.tableData;
          // this.getTableData(res.tableData);
        })
        .catch(() => {
          this.tableData = [];
        });
    },
    getTableData(list) {
      const keys = [];
      const obj = {};
      for (let i = 0; i < list.length; i += 1) {
        const item = list[i];
        keys.push(item.name);
        obj[item.name] = item.value;
      }
      console.log('tableData', this.tableData);
      this.keys = keys;
      this.tableData = [obj];
      console.log('tableData', this.tableData);
    },
  },
};
</script>
<style lang='less' scoped>
table {
  background: #898a8a;
}
/deep/.el-table--striped .el-table__body tr.el-table__row--striped td{
  background-color: #151A59;
  color:#fff;
  // background-color: #223388;
}
/deep/.el-table.dark th, .el-table.dark thead.is-group th{
  // background-color: #223388;
   background-color: #151A59;
   color:#8696BF;
  // background-color: transparent;
}
/deep/.el-table.dark tr, .el-table.dark, .el-table tr.dark{
  // background-color: #151A59;
  background-color: #223388;
  color:#fff;
}
</style>
