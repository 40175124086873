<template>
  <!-- 为 ECharts 准备一个具备大小（宽高）的 DOM -->
  <div v-if="seriesData" ref="gnfxdb"
   id="gnfxdb" style=""></div>
  <no-data-temporarily v-else></no-data-temporarily>
</template>

<script>
import { initResizeListener } from '@/utils/utils';
import NoDataTemporarily from '@/components/utils/no-data-temporarily.vue';
import { GetGongNengGaiKuangAndBarChart } from '../../../api/enery-analysis/eneryanalysis';

export default {
  components: {
    NoDataTemporarily,
  },
  data() {
    return {
      myChart: null,
      seriesData: null,
      xAxisData: null,
    };
  },
  props: {
    proId: String,
    selectPeriod: Array,
    periodType: String,
  },
  watch: {
    proId() {
      this.requestChartData();
    },
    selectPeriod() {
      this.requestChartData();
    },
    seriesData() {
      this.$nextTick(this.initData);
    },
    skinType() {
      this.initData();
    },
  },
  created() {
    this.requestChartData();
  },
  computed: {
    selectProject() {
      return this.$store.getters.selectProject;
    },
  },
  methods: {
    requestChartData() {
      if (!this.proId) {
        return;
      }
      const [start, end] = this.selectPeriod;
      GetGongNengGaiKuangAndBarChart({
        DateType: this.periodType,
        BeginDate: start,
        EndDate: end,
        ...this.proId === this.selectProject.IdStr ? {
          ProjectIDstr: this.proId,
        } : {
          StationIdstr: this.proId,
        },
      })
        .then((res) => {
          this.xAxisData = res.GongNengDuiBiFenXi.xAxisData;
          this.seriesData = res.GongNengDuiBiFenXi.seriesData;
        })
        .catch(() => {
          this.xAxisData = null;
          this.seriesData = null;
        });
    },
    initData() {
      const myChart = this.$echarts.init(document.getElementById('gnfxdb'));
      console.log(this.seriesData);
      // 绘制图表
      myChart.setOption({
        color: ['#75B2FB',
          '#08BED3'],
        grid: {
          top: 10,
          bottom: 0,
          left: 10,
          right: 20,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: this.xAxisData,
          axisLine: {
            // color: this.skinType ? '#fff' : '#333',
            color: '#fff',
          },
          axisLabel: {
            // color: this.skinType ? '#fff' : '#333',
            color: '#fff',
          },
        },
        yAxis: {
          type: 'value',
          name: 'kWh',
          // axisLine: {
          //   // color: this.skinType ? '#fff' : '#333',
          //   color: '#fff',
          // },
          axisLabel: {
            color: '#fff',
            formatter: '{value}',
          },
          // axisLabel: {
          //   // color: this.skinType ? '#fff' : '#333',
          //   color: '#fff',
          // },
          splitLine: {
            // show: true,
            lineStyle: {
              color: '#ccc',
              type: 'dashed',
            },
          },
        },
        series: [
          {
            barMinHeight: '10%',
            barWidth: '30%',
            barMaxWidth: '30%',
            data: this.seriesData,
            type: 'bar',
          },
        ],
      });
      initResizeListener(myChart, this.$refs.gnfxdb);
    },
  },
};
</script>
