<template>
  <div class="energy-consumption-statistics d-flex flex-column">
    <div
      class="
        select-living
        d-flex
        align-items-center
        section-container
        dark
        selector-container
      "
    >
      <Backup></Backup>
      <el-form class="" :inline="true">
        <!-- <project-level-selector
          @selectItem="selectItemListener"
          style="margin-right: 1rem;"
        ></project-level-selector> -->
        <RoomSelector @selectItem="selectItemListener"></RoomSelector>
        <el-form-item label="统计方式:" class="light-label">
          <el-select v-model="periodType" size="small" class="xlk">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间:" class="light-label">
          <el-date-picker
            v-model="selectPeriod"
            size="small"
            :type="periodType == 'Day' ? 'daterange' : 'monthrange'"
            :format="periodType === 'Day' ? 'yyyy-MM-dd' : 'yyyy-MM'"
            :value-format="periodType === 'Day' ? 'yyyy-MM-dd' : 'yyyy-MM'"
            range-separator="至"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 1rem;
            border:1px solid #25C1FF;
            background-color:#223388;
            color:#25C1FF;
            "
            @click="onSubmit"
            >查询</el-button
          ></el-form-item
        >
      </el-form>
    </div>
    <div class="d-flex flex-column none-height flex-grow-1">
      <el-row
        class="part-container align-items-stretch none-height flex-grow-1"
        type="flex"
        :gutter="12"
      >
        <el-col :span="8" class="d-flex">
          <SectionLiving title="供电概况" class="flex-grow-1 d-flex">
            <el-row class="flex-grow-1" type="flex" v-if="briefInfo">
              <el-col
                :span="8"
                class="
                  general-situation
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                "
              >
                <!-- <img :src="gongdian1" class="section-image" /> -->
                <img
                  src="../../assets/images/nengxiaotongji/gongdian1.png"
                  alt=""
                />
                <span style="margin: 0.5rem 0rem 1rem 0rem ">总供电量</span>
                <span
                  ><span
                    style="
                      color: #25C1FF;
                      font-size: 1.2rem;
                      margin-top: 0.5rem;
                      padding-right:0.3rem;
                    "
                    >{{ briefInfo.GongDianLiang }}</span
                  ><span class="">kWh</span></span
                >
              </el-col>
              <el-col
                :span="8"
                class="
                  general-situation
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                "
              >
                <!-- <img :src="img2" class="section-image" /> -->
                <img
                  src="../../assets/images/nengxiaotongji/gongdian2.png"
                  alt=""
                />
                <span style="margin: 0.5rem 0rem 1rem 0rem ">总用电量</span>
                <span
                  ><span
                    style="
                      color: #25C1FF;
                      font-size: 1.2rem;
                      margin-top: 0.5rem;
                      padding-right:0.3rem;
                    "
                    >{{ briefInfo.YongDianLiang }}</span
                  ><span class="">kWh</span></span
                >
              </el-col>
              <el-col
                :span="8"
                class="
                  general-situation
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                "
              >
                <!-- <img :src="img3" class="section-image" /> -->
                <img
                  src="../../assets/images/nengxiaotongji/gongdian3.png"
                  alt=""
                />
                <span style="margin: 0.5rem 0rem 1rem 0rem ">损失电量(占比)</span>
                <span
                  ><span
                    style="
                      color: #25C1FF;
                      font-size: 1.2rem;
                      margin-top: 0.5rem;
                      padding-right:0.3rem;
                    "
                    >{{ briefInfo.SunShiDianLiang }}</span
                  ><span class="">kWh({{ briefInfo.ZhanBi }}%)</span></span
                >
              </el-col>
            </el-row>
            <no-data-temporarily
              v-else
              style="width: 100%; height: 100%"
            ></no-data-temporarily>
          </SectionLiving>
        </el-col>
        <el-col :span="8" class="d-flex">
          <SectionLiving
            title="电能消耗分析-分区对比(kWh)"
            class="flex-grow-1 d-flex"
          >
            <fenquduibi
              :proId="proId"
              :selectPeriod="selectPeriod"
              :periodType="periodType"
            />
          </SectionLiving>
        </el-col>
        <el-col :span="8" class="d-flex">
          <SectionLiving
            title="电能消耗分析-分项对比(kWh)"
            class="flex-grow-1 d-flex"
          >
            <fenxiangbi
              :proId="proId"
              :selectPeriod="selectPeriod"
              :periodType="periodType"
            />
          </SectionLiving>
        </el-col>
      </el-row>
      <el-row
        class="
          part-container
          d-flex
          align-items-stretch
          none-height
          flex-grow-2
        "
        :gutter="12"
      >
        <el-col :span="8" class="d-flex">
          <SectionLiving
            title="供电对比分析(kWh)"
            class="flex-grow-1 d-flex flex-column d-flex flex-column"
          >
            <div class="d-flex flex-column flex-grow-1" style="padding: 0.6rem">
              <gnfxdb
                class="none-height flex-grow-1"
                :proId="proId"
                :selectPeriod="selectPeriod"
                :periodType="periodType"
              />
              <nhtjeryoushuju
                class="none-height flex-grow-1"
                style="margin-top: 0.6rem"
                :proId="proId"
                :selectPeriod="selectPeriod"
                :periodType="periodType"
              />
            </div>
          </SectionLiving>
        </el-col>
        <el-col :span="16" class="d-flex flex-column">
          <SectionLiving
            title="电能消耗对比分析-分区对比(kWh)"
            class="none-height flex-grow-1 d-flex flex-column"
          >
            <div
              class="dark none-height d-flex flex-column flex-grow-1"
              style="padding-top: 0.6rem"
            >
              <el-radio-group
                v-model="radioList2"
                size="mini"
                style="text-align: end; position: absolute; right: 5%; top: 3%"
              >
                <el-radio-button label="1">图形</el-radio-button>
                <el-radio-button label="2">数据</el-radio-button>
              </el-radio-group>
              <div
                class="
                  none-height
                  flex-grow-1
                  d-flex
                "
                :class="{ dark: skinType }"
              >
                <duibifenqubi
                  v-if="this.radioList2 == 1"
                  class="none-width flex-grow-1 d-flex"
                  :proId="proId"
                  :selectPeriod="selectPeriod"
                  :periodType="periodType"
                />
                <duibifenxibiao
                  v-else
                  class="none-width flex-grow-1"
                  :proId="proId"
                  :selectPeriod="selectPeriod"
                  :periodType="periodType"
                />
              </div>
            </div>
          </SectionLiving>
          <div style="height: 1rem"></div>
          <SectionLiving
            title="电能消耗对比分析-分项对比(kWh)"
            class="none-height flex-grow-1 d-flex flex-column"
          >
            <div
              class="dark none-height d-flex flex-column flex-grow-1"
              style="padding-top: 0.6rem"
            >
              <el-radio-group
                v-model="radioList4"
                size="mini"
                style="text-align: end; position: absolute; right: 5%; top: 55%;"
              >
                <el-radio-button label="1">图形</el-radio-button>
                <el-radio-button label="2">数据</el-radio-button>
              </el-radio-group>
              <div
                class="
                  none-height
                  flex-grow-1
                  d-flex
                "
                :class="{ dark: skinType }"
              >
                <duibifenxiangbi
                  v-if="this.radioList4 == 1"
                  class="none-width flex-grow-1"
                  :proId="proId"
                  :selectPeriod="selectPeriod"
                  :periodType="periodType"
                />
                <duibifenxiangbiao
                  v-else
                  class="none-width flex-grow-1"
                  :proId="proId"
                  :selectPeriod="selectPeriod"
                  :periodType="periodType"
                />
              </div>
            </div>
          </SectionLiving>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import {
  GetGongNengGaiKuangAndBarChart,
  GetDianNengXaoHaoForPieChart,
} from '@/api/enery-analysis/eneryanalysis';
import { formatDate } from '@/utils/utils';
import NoDataTemporarily from '@/components/utils/no-data-temporarily.vue';
import SectionLiving from '@/components/utils/section-living.vue';
import warningColorPng from '@/assets/images/yyimg/warning-color.png';
import visualizeColorPng from '@/assets/images/yyimg/visualize-color.png';
import amountColorPng from '@/assets/images/yyimg/amount-color.png';
// import ProjectLevelSelector from '@/components/utils/project-level-selector.vue';
import RoomSelector from '@/components/utils/room-selector.vue';
import gnfxdb from '../platform/ym/gnfxdb.vue';
import nhtjeryoushuju from '../platform/ym/nhtjeryoushuju.vue';
import fenxiangbi from '../platform/ym/fenxiangbi.vue';
import duibifenqubi from '../platform/ym/duibifenqubi.vue';
import duibifenxibiao from '../platform/ym/duibifenxibiao.vue';
import duibifenxiangbi from '../platform/ym/duibifenxiangbi.vue';
import duibifenxiangbiao from '../platform/ym/duibifenxiangbiao.vue';
import fenquduibi from '../platform/ym/fenquduibi.vue';
import Backup from '../utils/backup.vue';

export default {
  data() {
    return {
      seriesData: [],
      xAxisData: [],
      proId: '',
      daterange: '',
      img1: warningColorPng,
      img2: visualizeColorPng,
      img3: amountColorPng,
      options: [
        {
          value: 'Day',
          label: '日',
        },
        {
          value: 'Month',
          label: '月',
        },
      ],
      periodType: 'Day',
      selectPeriod: [
        formatDate(new Date(), 'yyyy-MM-01'),
        formatDate(new Date(), 'yyyy-MM-dd'),
      ],
      radioList1: 1,
      radioList2: 1,
      radioList4: 1,
      briefInfo: null,
    };
  },
  components: {
    gnfxdb,
    nhtjeryoushuju,
    fenxiangbi,
    duibifenqubi,
    duibifenxibiao,
    duibifenxiangbi,
    duibifenxiangbiao,
    fenquduibi,
    NoDataTemporarily,
    SectionLiving,
    Backup,
    // ProjectLevelSelector,
    RoomSelector,
  },
  watch: {
    periodType() {
      this.selectPeriod = this.getInitialPeriod();
    },
    selectPeriod() {
      this.requestDataList();
    },
    selectProject() {
      this.requestDataList();
    },
    proId() {
      this.requestDataList();
    },
  },
  created() {
    this.requestDataList();
  },
  computed: {
    skinType() {
      return this.$store.getters.skinType;
    },
    selectProject() {
      return this.$store.getters.selectProject;
    },
  },
  methods: {
    onSubmit() {
      this.requestDataList();
    },
    requestDataList() {
      this.requestPowerProfile();
      this.requestPowerConsumption();
    },
    requestPowerProfile() {
      console.log('requestPowerProfile', this.proId);
      if (!this.proId) {
        return;
      }
      const [start, end] = this.selectPeriod;
      console.log('this.selectPeriod', this.selectPeriod);
      const params = {
        DateType: this.periodType,
        BeginDate: start,
        EndDate: end,
        ...this.proId === this.selectProject.IdStr ? {
          ProjectIDstr: this.proId,
        } : {
          StationIdstr: this.proId,
        },
      };
      console.log('params', params);
      GetGongNengGaiKuangAndBarChart(params)
        .then((res) => {
          this.briefInfo = res.GongNengGaiKuang;
          console.log('this.briefInfo:', this.briefInfo);
        })
        .catch((err) => {
          console.log('供能概况err', err);
          this.briefInfo = null;
        });
    },
    requestPowerConsumption() {
      if (!this.proId) {
        return;
      }
      const [start, end] = this.selectPeriod;
      const params = {
        DateType: this.periodType,
        BeginDate: start,
        EndDate: end,
        ...this.proId === this.selectProject.IdStr ? {
          ProjectIDstr: this.proId,
        } : {
          StationIdstr: this.proId,
        },
      };
      GetDianNengXaoHaoForPieChart(params)
        .then((res) => {
          this.FenXiangData = res.FenXiangData;
          console.log(this.FenXiangData);
          console.log('00000');
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getInitialPeriod() {
      let formatStr;
      switch (this.periodType) {
        case 'Day':
          formatStr = ['yyyy-MM-01', 'yyyy-MM-dd'];
          break;
        case 'Month':
          formatStr = ['yyyy-01', 'yyyy-MM'];
          break;
        default:
          formatStr = [];
          break;
      }
      console.log('formatStr', formatStr);
      return formatStr.map((format) => formatDate(new Date(), format));
    },
    // selectItemListener(selectNode) {
    //   this.selectNode = selectNode;
    //   this.proId = selectNode.IdStr;
    // },
    // 接受选择配电室的值
    selectItemListener(selectNode) {
      if (selectNode.oType !== 'pds') {
        return;
      }
      this.selectNode = selectNode;
      this.proId = selectNode.IdStr;
    },
  },
};
</script>

<style lang="less" scoped>
.general-situation {
  position: relative;
}
.general-situation + .general-situation:before {
  position: absolute;
  content: " ";
  width: 6px;
  height: 60px;
  left: 0;
  background-image: url(../../assets/images/yyimg/line.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
/deep/.el-date-editor--daterange.el-input,
/deep/.el-date-editor--daterange.el-input__inner,
/deep/.el-date-editor--timerange.el-input,
/deep/.el-date-editor--timerange.el-input__inner {
  width: 260px;
}
.general-situation img {
  width: 2.8rem;
  height: 3rem;
  // border: 1px solid red;
}
.flex-grow-2 {
  flex-grow: 2;
}
.xlk {
  width: 5rem;
  margin-right: 1rem;
}
.part-container + .part-container {
  margin-top: 1rem;
}
.tubiaozuo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tubiaoyou {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
// select-container{
//   padding: 1rem;
//   margin-bottom: 1rem;
// }
.select-living {
  padding: 1rem;
  margin-bottom: 1rem;
}
.tongjifangshi {
  height: 80%;
  font-size: 1.2rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}
.section-image {
  height: 2.6rem;
}
.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}
// 图形按钮
/deep/.dark .el-radio-button .el-radio-button__inner{
  background-color: #183D7C;
}
.el-radio-button{
  box-shadow: 0px 0px 4px rgba(37, 193, 255, 0.8);
}
// 查询
.el-form--inline .el-form-item{
  vertical-align:baseline;
}
/deep/.el-form--inline .el-form-item__content{
  vertical-align:baseline;
}
</style>
