<template>
  <el-form-item label="配电室:" class="light-label" size="mini">
    <el-select v-model="selectId">
      <el-option v-for="(room,index) of selectProject.Children"
      :key="index" :label="room.ModelName" :value="room.IdStr" >{{room.ModelName}}</el-option>
    </el-select>
  </el-form-item>
</template>
<script>
export default {
  name: 'room-selector',
  computed: {
    selectProject() {
      return this.$store.getters.selectProject;
    },
  },
  data() {
    return {
      selectId: '',
      selectItem: null,
    };
  },
  watch: {
    selectId() {
      this.selectItem = this.getSelectItemById(this.selectProject);
    },
    selectItem() {
      this.$emit('selectItem', this.selectItem);
    },
  },
  mounted() {
    [this.selectItem] = this.selectProject.Children;
    this.selectId = this.selectItem.IdStr;
  },
  methods: {
    getSelectItemById(item) {
      if (item.IdStr === this.selectId) {
        return item;
      }
      if (!item.Children) {
        return null;
      }
      for (let i = 0; i < item.Children.length; i += 1) {
        const info = this.getSelectItemById(item.Children[i]);
        if (info) {
          return info;
        }
      }
      return null;
    },
  },
};
</script>
