<template>
  <!-- 为 ECharts 准备一个具备大小（宽高）的 DOM -->
  <div v-if="FenXiangData" ref="fenxiangbi" id="fenxiangbi" style="width: 100%; height: 100%"></div>
  <no-data-temporarily v-else  style="width: 100%; height: 100%"></no-data-temporarily>
</template>
<script>
import { initResizeListener } from '@/utils/utils';
import NoDataTemporarily from '@/components/utils/no-data-temporarily.vue';
import { GetDianNengXaoHaoForPieChart } from '../../../api/enery-analysis/eneryanalysis';

export default {
  data() {
    return {
      FenXiangData: null,
    };
  },
  components: {
    NoDataTemporarily,
  },
  props: {
    proId: String,
    selectPeriod: Array,
    periodType: String,
  },
  watch: {
    proId() {
      this.requestData();
    },
    selectPeriod() {
      this.requestData();
    },
    FenXiangData() {
      this.$nextTick(this.initData);
    },
    skinType() {
      this.initData();
    },
  },
  created() {
    this.requestData();
  },
  computed: {
    selectProject() {
      return this.$store.getters.selectProject;
    },
  },
  methods: {
    // 初始化数据
    requestData() {
      if (!this.proId) {
        return;
      }
      const [start, end] = this.selectPeriod;
      GetDianNengXaoHaoForPieChart({
        DateType: this.periodType,
        BeginDate: start,
        EndDate: end,
        ...this.proId === this.selectProject.IdStr ? {
          ProjectIDstr: this.proId,
        } : {
          StationIdstr: this.proId,
        },
      })
        .then((res) => {
          this.FenXiangData = res.FenXiangData;
        }).catch(() => {
          this.FenXiangData = null;
        });
    },
    initData() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('fenxiangbi'));
      // 绘制图表
      myChart.setOption({
        color: this.skinType ? ['#FFAF8E', '#667EEB', '#75B2FB', '#08BED3', '#F67CA3', '#7FC4C2']
          : ['#5BFFB0', '#00FFFF ', '#FFDE5B', '#2AC1FF', '#f785b1'],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}({d}%)',
        },
        legend: {
          orient: 'vertical',
          top: 0,
          right: 0,
          textStyle: {
            // color: 'auto',
            color: '#fff',
          },
          icon: 'circle',
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 5,
        },
        series: [
          {
            name: '电能消耗分析-分项对比',
            type: 'pie',
            radius: ['30%', '60%'],
            center: ['45%', '50%'],
            data: this.FenXiangData,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  formatter: '{b} : {c} \n ({d}%)',
                  // color: 'auto',
                  color: '#fff',
                },
                labelLine: {
                  show: true,
                },
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      });
      initResizeListener(myChart, this.$refs.fenxiangbi);
    },
  },
};
</script>
<style scoped>
.pie {
  position: relative;
  background: #55b9f3;
}
</style>
