<template>
<div class="dark">
  <el-table
    stripe
    size="mini"
    :data="tableData"
    height="100%"
    class="dark"
    :row-class-name="(...nu)=>{return skinType?'dark text-white':''}"
    :cell-style="cellStyle"
  >
   <el-table-column v-for="(col,index) of tableColumn" width="88"
   :fixed="col=='type'?'fixed':false"
    :key="index" :prop="col" :label="col=='type'?'类型':col" align="center">
    </el-table-column>
  </el-table>
  </div>
</template>

<script>
import { GetDianNengXiaoHaoForBarChart } from '../../../api/enery-analysis/eneryanalysis';

export default {
  data() {
    return {
      tableData: [],
      tableColumn: [],
    };
  },
  props: {
    proId: String,
    selectPeriod: Array,
    periodType: String,
  },
  watch: {
    proId() {
      this.requestData();
    },
    selectPeriod() {
      this.requestData();
    },
  },
  created() {
    this.requestData();
  },
  computed: {
    selectProject() {
      return this.$store.getters.selectProject;
    },
  },
  methods: {
    requestData() {
      if (!this.proId) {
        return;
      }
      const [start, end] = this.selectPeriod;
      GetDianNengXiaoHaoForBarChart({
        DateType: this.periodType,
        BeginDate: start,
        EndDate: end,
        ...this.proId === this.selectProject.IdStr ? {
          ProjectIDstr: this.proId,
        } : {
          StationIdstr: this.proId,
        },
      })
        .then((res) => {
          this.tableData = res.fenxiangData.tableData;
          if (res.fenxiangData.tableColumn.length > 0) {
            res.fenxiangData.tableColumn.unshift('type');
          }
          this.tableColumn = res.fenxiangData.tableColumn;
        }).catch(() => {
          this.tableData = [];
          this.tableColumn = [];
        });
    },
    // 设置指定行、列、具体单元格颜色
    cellStyle({
      rowIndex, columnIndex,
    }) {
      if (rowIndex === 0 && columnIndex === 0) { // 指定坐标rowIndex ：行，columnIndex ：列
        return 'color:#019dd8'; // rgb(105,0,7)
      } if (rowIndex === 1 && columnIndex === 0) {
        return 'color:#8d9eae';
      } if (rowIndex === 2 && columnIndex === 0) {
        return 'color:#0bd0d9';
      } if (rowIndex === 3 && columnIndex === 0) {
        return 'color:#0f6fc6';
      }
      return '';
    },
  },
};
</script>
<style lang='less' scoped>
table {
  background: #898a8a;
}
/deep/.el-table.dark th, .el-table.dark thead.is-group th{
  // background-color: #223388;
   background-color: #151A59;
   color:#8696BF;
  // background-color: transparent;
}
/deep/.el-table.dark tr, .el-table.dark, .el-table tr.dark{
  // background-color: #151A59;
  background-color: #223388;
  color:#fff;
}
/deep/.el-table--striped .el-table__body tr.el-table__row--striped td{
  background-color: #223388;

}
</style>
